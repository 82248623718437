import React from "react";

import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import PreviewAktivität from "../../components/PreviewAktivitaet";
import ProfilfelderOverview from "../../components/ProfilfelderOverview";

function ProfilfelderPage({ data }) {
  const aktivitaets = data.allStrapiAktivitaet.nodes;
  return (
    <Layout>
      <SEO
        keywords={[
          `Klimaentscheid`,
          `Bayreuth`,
          `GermanZero`,
          `Bürgerbegehren`,
          "Bürgerentscheid",
        ]}
        title="b.iwu"
      />

      <div style={{ marginBottom: "-3em" }}>
        <ProfilfelderOverview />
      </div>
    </Layout>
  );
}

export default ProfilfelderPage;

export const query = graphql`
  query {
    allStrapiAktivitaet {
      nodes {
        Aktivitaetslink
        Content
        Kurzbeschreibung
        Slug
        Titel
        kompetenzfelds {
          Titel
        }
        aktivitatskategories {
          Slug
        }
        saules {
          Slug
        }
      }
    }
  }
`;
